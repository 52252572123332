import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import SectionRow from 'app/components/SectionRow';
import CustomisableConfig from './CustomisableConfig';
import ApiAccessModal from './ApiAccessModal';
import { ChannelConfig } from './ChannelConfigScreen';

interface ApiAccessSectionProps {
	saveConfiguration: (overrides: Partial<ChannelConfig>) => void;
	packageDefault: {
		isEnabled: boolean;
	};
	currentValues: {
		isEnabled: boolean;
	};
}

const ApiAccessSection = ({ saveConfiguration, packageDefault, currentValues }: ApiAccessSectionProps) => {
	const onSave = (value: { isEnabled: boolean }) => {
		saveConfiguration({
			apiAccess: {
				isEnabled: value.isEnabled
			}
		});
	};

	const [isEditing, setIsEditing] = useState(false);
	return (
		<>
			<Box className="section" component="div">
				<div className="sectionHeader">
					<span className="sectionTitle">API Access</span>
					<Tooltip title="Edit fans data config" arrow>
						<IconButton onClick={() => setIsEditing(true)} size="large">
							<EditIcon className="editIcon" />
						</IconButton>
					</Tooltip>
				</div>
				<SectionRow
					label="Can use API"
					content={
						<CustomisableConfig
							value={currentValues.isEnabled ? 'Yes' : 'No'}
							customisedText={
								currentValues.isEnabled != packageDefault.isEnabled
									? `(default is ${packageDefault.isEnabled ? 'Yes' : 'No'})`
									: ''
							}
						/>
					}
				/>
			</Box>
			<ApiAccessModal
				isOpen={isEditing}
				onClose={() => setIsEditing(false)}
				onSave={onSave}
				currentValues={currentValues}
			/>
		</>
	);
};

export default ApiAccessSection;
