import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch } from '@mui/material';
import React, { useState } from 'react';
import {
	hasAccessToApi,
	hasAccessToFansData,
	PublisherStaff,
	ResourcePermissionType
} from '../../../../../app/domain/Admin';

interface Props {
	canEditApiAccess: boolean;
	canEditSharedData: boolean;
	channelName: string;
	admin: PublisherStaff | null;
	onCancel: () => void;
	onSave: (userUid: string, permission: ResourcePermissionType[]) => void;
}

const EditAdminModal = ({ canEditSharedData, canEditApiAccess, channelName, admin, onSave, onCancel }: Props) => {
	const [accessToFansData, setAccessToFansData] = useState<boolean>(hasAccessToFansData(admin));
	const [accessToApi, setAccessToApi] = useState<boolean>(hasAccessToApi(admin));

	const handleSetAccessToFansData = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAccessToFansData(event.target.checked);
	};

	const handleAccessToApi = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAccessToApi(event.target.checked);
	};

	const onClick = () => {
		const permission = admin!.permission.filter(
			singlePermission =>
				singlePermission !== ResourcePermissionType.fanDataAdmin &&
				singlePermission !== ResourcePermissionType.developer
		);

		if (accessToFansData) {
			permission.push(ResourcePermissionType.fanDataAdmin);
		}

		if (accessToApi) {
			permission.push(ResourcePermissionType.developer);
		}

		onSave(admin!.userUid, permission);
	};

	return (
		<Dialog className="editAdminDialog" fullWidth open={true} onClose={onCancel}>
			<div className="container">
				<DialogTitle>
					<span className="title">Edit Admin Status for Fan Data and API Access</span>
				</DialogTitle>
				<DialogContent>
					<div className="description">
						Admin on Channel: <span className="description-highlighted">{channelName}</span>
					</div>
					<div>
						<FormControlLabel
							color="default"
							control={
								<Switch
									disabled={!canEditSharedData}
									checked={accessToFansData}
									onChange={handleSetAccessToFansData}
								/>
							}
							label={<span className="toggleButton">Has access to Fans Data</span>}
						/>
					</div>
					<div>
						<FormControlLabel
							color="default"
							control={
								<Switch
									disabled={!canEditApiAccess}
									checked={accessToApi}
									onChange={handleAccessToApi}
								/>
							}
							label={<span className="toggleButton">Can create and manage API keys</span>}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="text" onClick={onCancel}>
						<span className="button">Cancel</span>
					</Button>
					<Button
						variant="contained"
						onClick={onClick}
						color="secondary"
						disableElevation
						disabled={
							hasAccessToFansData(admin!) === accessToFansData && hasAccessToApi(admin!) === accessToApi
						}
					>
						<span className="button">Confirm & Save</span>
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};

export const DisabledEditAdminModal = ({ channelName, onCancel }: { channelName: string; onCancel: () => void }) => {
	return (
		<Dialog className="editAdminDialog" fullWidth open={true} onClose={onCancel}>
			<div className="container">
				<DialogTitle>
					<span className="title-disabled">Edit Admin Status for Accessing Fans Data</span>
				</DialogTitle>
				<DialogContent>
					<div className="description">
						Admin on Channel: <span className="description-highlighted-disabled">{channelName}</span>
					</div>
					<div>
						<FormControlLabel
							color="default"
							control={<Switch disabled={true} />}
							label={<span className="toggleButton">Has access to Fans Data</span>}
						/>
					</div>
					<div>
						<FormControlLabel
							color="default"
							control={<Switch disabled={true} />}
							label={<span className="toggleButton">Can create and manage API keys</span>}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="text" onClick={onCancel}>
						<span className="button">Cancel</span>
					</Button>
					<Button variant="contained" color="secondary" disableElevation disabled={true}>
						<span className="button">Confirm & Save</span>
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};

export default EditAdminModal;
