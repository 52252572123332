import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Switch
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';

interface ApiAccessModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (value: { isEnabled: boolean }) => void;
	currentValues: { isEnabled: boolean };
}

const ApiAccessModal = ({ isOpen, onClose, onSave, currentValues }: ApiAccessModalProps) => {
	return (
		<Dialog className="channelConfigModal" open={isOpen} onClose={onClose}>
			<DialogTitle>
				<strong>API Access</strong>
			</DialogTitle>
			<Formik
				enableReinitialize
				initialValues={{
					isEnabled: currentValues.isEnabled
				}}
				onSubmit={values => {
					onSave(values);
					onClose();
				}}
			>
				{({ values, setFieldValue, dirty, isValid }) => (
					<Form>
						<DialogContent>
							<Box sx={{ paddingBottom: 'inherit' }}>
								<div className="helpText">
									Brands with API access enabled can set admin permissions to manage and use API keys.
								</div>
								<div className="horizontalAlign">
									<FormControlLabel
										name="isEnabled"
										className="fanDataSwitch"
										onChange={() => setFieldValue('isEnabled', !values.isEnabled)}
										control={<Switch checked={values.isEnabled} />}
										label={<span className="additionalFormText">Can use API</span>}
									/>
								</div>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} variant="text" color="primary">
								Cancel
							</Button>
							<Button type="submit" color="secondary" variant="contained" disabled={!dirty || !isValid}>
								Confirm & Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default ApiAccessModal;
